const Contract = (name, address, abi) => ({
  name, address, abi,
});

export const contracts = [
  // Minimal TGE DEX ABI
  Contract(
    'TGE',
    '0x4d55f76ce2dbbae7b48661bef9bd144ce0c9091b',
    [
      // withdraw - withdraw ETH
      {
        "constant": false,
        "inputs": [
          {
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "withdraw",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      // withdrawToken
      {
        "constant": false,
        "inputs": [{ "name": "token", "type": "address" }, { "name": "amount", "type": "uint256" }],
        "name": "withdrawToken",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      // tokens - token balance for user
      {
        "constant": true,
        "inputs": [{ "name": "", "type": "address" }, { "name": "", "type": "address" }],
        "name": "tokens",
        "outputs": [{ "name": "", "type": "uint256" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      }
    ]
  ),
];