import Web3 from "web3";

export const getWeb3 = async () => {
  let readyWeb3;

  // Default behaviour is to look for an injected
  // web3 instance in the window
  const ethereum = (window).ethereum;
  const web3 = (window).web3;

  if (ethereum) {
    try {
      // Request account access if needed
      await ethereum.enable();

      // Acccounts now exposed
      readyWeb3 = new Web3(ethereum);
    } catch (error) {
      return Promise.reject("User denied account access...");
    }
  }
  // Legacy dapp browsers...
  else if (web3) {
    readyWeb3 = new Web3(web3.currentProvider);
  }
  // Non-dapp browsers...
  else {
    return Promise.reject(
      "Non-Ethereum browser detected. You should consider trying MetaMask!"
    );
  }

  const accounts = await readyWeb3.eth.getAccounts();
  readyWeb3.eth.defaultAccount = accounts[0];

  return readyWeb3;
};

export const getProviderSelectedAddress = async () => {
  const web3 = await getWeb3();
  const address = web3.eth.defaultAccount;
  return address;
};
