import { resetStore, setBadNetwork } from '../store/actions';

if (window.ethereum) {
  window.ethereum.on('accountsChanged', function () {
    resetStore();
  })

  window.ethereum.on('networkChanged', function (networkId) {
    setBadNetwork(networkId);
  });

  // set bad network immediately on launch if necessary
  if (window.ethereum && window.ethereum.networkVersion) {
    setBadNetwork(window.ethereum.networkVersion);
  }
}