import { createStore } from 'redux';
import update from 'immutability-helper';

import { pruneAndSortBalances } from '../utils';

const defaultState = {
    isConnecting: false,
    isConnected: false,

    isMainnet: true,

    address: '',
    addressTruncated: '',
    balances: [],
};

function getDefaultState() {
    return Object.assign({}, defaultState);
}

const store = createStore((
    state = getDefaultState(),
    action
) => {
    switch (action.type) {
        case 'reset': {
            return getDefaultState();
        }
        case 'bad_network': {
            return update(state, {
                isMainnet: { $set: false },
            });
        }
        // BEGIN Connect
        case 'connect/begin': {
            return update(state, {
                isConnecting: { $set: true },
                isConnected: { $set: false },
            });
        }
        case 'connect/fail': {
            return update(state, {
                isConnecting: { $set: false },
                isConnected: { $set: false },
            });
        }
        case 'connect/end': {
            return update(state, {
                isConnecting: { $set: false },
                isConnected: { $set: true },
            });
        }
        // END Connect

        // BEGIN Balances
        case 'balances/set': {
            // prune tokens with 0-balances
            const address = action.address;
            const balances = pruneAndSortBalances(action.balances);
            const addressTruncated = `${address.slice(0, 6)}...${address.slice(-4)}`

            return update(state, {
                address: { $set: address },
                addressTruncated: { $set: addressTruncated },
                balances: { $set: balances },
            });
        }
        // END Balances

        // BEGIN Withdraw
        case 'withdraw/begin': {
            return update(state, {
                balances: {
                    [action.index]: {
                        withdrawing: { $set: true },
                    }
                },
            });
        }
        case 'withdraw/fail': {
            return update(state, {
                balances: {
                    [action.index]: {
                        withdrawing: { $set: false },
                    }
                },
            });
        }
        case 'withdraw/end': {
            // remove the withdrawn token from the array
            return update(state, {
                balances: { $splice: [[action.index, 1]] },
            });
        }
        // END Withdraw

        default:
            return state;
    }
}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

export default store;
