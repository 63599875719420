import React from 'react';
import { connect } from 'react-redux'

import './App.css';
import Header from './Header';
import Footer from './Footer';
import Toast from './Toast';
import NotConnectedScreen from './NotConnectedScreen';
import ConnectedScreen from './ConnectedScreen';

function App(props) {
  return <div className="app">
    <Toast />
    <Header />
    {
      !props.isMainnet ?
        (
          <p>Your are connected to an Ethereum testnet. Please connect to the Ethereum Mainnet.</p>
        ) : (
          <div>
            {
              props.isConnected ?
                <ConnectedScreen /> :
                <NotConnectedScreen />
            }
          </div>
        )
    }
    <Footer />
  </div>
}

const mapStateToProps = ({
  isConnected,
  isMainnet,
}) => ({
  isConnected,
  isMainnet,
});

export default connect(
  mapStateToProps
)(App);
