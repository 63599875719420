import { getWeb3 } from "../core";
import { contracts } from '../../fixtures/contracts';

export async function getContractInterfaces() {
  const web3 = await getWeb3();

  return contracts.reduce((acc, contract) => {
    acc[contract.name] = new web3.eth.Contract(
      contract.abi,
      contract.address,
    );

    return acc;
  }, {})
};
