import store from '../store';

// Reset
export function resetStore() {
    store.dispatch({
        type: 'reset',
    });
}

// Set bad network (not mainnet)
export function setBadNetwork(networkId) {
    resetStore();
    if (networkId !== '1') {
        store.dispatch({
            type: 'bad_network',
        });
    }
}

// BEGIN Connecting
export function connectBegin() {
    store.dispatch({
        type: 'connect/begin',
    });
}
export function connectFail() {
    store.dispatch({
        type: 'connect/fail',
    });
}
export function connectEnd() {
    store.dispatch({
        type: 'connect/end',
    });
}
// END Connecting

// BEGIN Balances
export function setBalances(balances, address) {
    store.dispatch({
        type: 'balances/set',
        balances,
        address,
    });
}
// END Balances

// BEGIN Withdraw
export function withdrawBegin(index) {
    store.dispatch({
        type: 'withdraw/begin',
        index,
    });
}
export function withdrawFail(index) {
    store.dispatch({
        type: 'withdraw/fail',
        index,
    });
}
export function withdrawEnd(index) {
    store.dispatch({
        type: 'withdraw/end',
        index,
    });
}
// END Withdraw
