import React from 'react';

import { ToastContainer  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Toast.css';

export default function Toast() {
    return (<ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        // pauseOnFocusLoss
        closeOnClick={false}
        // pauseOnHover
        // draggable
    />);
}
