import { toast } from 'react-toastify';

import { withdrawToken, withdrawETH, getERC20Tokens, getWeb3 } from '../web3';

import store from '../store';
import {
    connectBegin,
    connectFail,
    connectEnd,
    setBalances,
    withdrawBegin,
    withdrawFail,
    withdrawEnd,
} from '../store/actions';

export function connectAndPullBalances() {
    connectBegin();
    getERC20Tokens()
        .then(async (response) => {
            setBalances(
                response.balances,
                response.address,
            );
            connectEnd();
        })
        .catch((e) => {
            console.error(e);
            toast('Failed to connect.', { type: 'error' });
            connectFail();
        });
}

export function withdrawUserToken(i) {
    const { balances, address } = store.getState();
    let token = balances[i];
    withdrawBegin(i);
    (
        // call a different withdrawal function if this is an ETH withdrawal
        token.address === `0x${'0'.repeat(40)}` ?
            withdrawETH(address, token.rawBalance) :
            withdrawToken(address, token.address, token.rawBalance)
    )
        .then(() => {
            withdrawEnd(i);
        })
        .catch((e) => {
            console.error(e);
            toast(`Failed to withdraw your ${token.name} tokens.`, { type: 'error' });
            withdrawFail(i);
        });
}

export const divideNumberByDecimals = (num, decimals) => {
    return num / Math.pow(10, decimals);
}

export function pruneAndSortBalances(_balances) {
    const balances = _balances.reduce((acc, token) => {
        if (token.rawBalance != '0') {
            acc.push(token);
        }
        return acc;
    }, []);

    balances.sort((a, b) => b.balance - a.balance);

    return balances;
}
