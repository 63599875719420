import React from 'react';
import { connect } from 'react-redux';

import { connectAndPullBalances } from '../utils';

export function NotConnectedScreen(props) {
  const hasEthereumProvider = !!(window.ethereum || window.web3);
  return <div>

    {hasEthereumProvider ? (
      <div>
        <h1>Connect to MetaMask to withdraw your assets</h1>
        <p>
          In order to withdraw your assets from the TGE app, we need you to connect
          to the MetaMask account you used to create your account.
        </p>
        <button
          onClick={() => connectAndPullBalances()}
          disabled={props.isConnecting}
        >
          {props.isConnecting ? "Connecting" : "Connect"}
        </button>
      </div>
    ) : (
      <div>
        <h1>MetaMask not found</h1>
        <p>
          Please try installing <a href="https://metamask.io/download.html">MetaMask</a>
          , <a href="https://brave.com/download">Brave</a>, or another Ethereum provider.
        </p>
      </div>
    )}
  </div>;
}

const mapStateToProps = ({
  isConnecting,
}) => ({
  isConnecting,
});

export default connect(
  mapStateToProps
)(NotConnectedScreen);
