import React from 'react';
import { connect } from 'react-redux'

import Asset from './Asset';

import './ConnectedScreen.css';

const NO_TOKENS_MSG = "You do not have any tokens to withdraw.";
const TOKENS_MSG = "To withdraw your assets, click the withdraw button and confirm the transaction on MetaMask.";

function ConnectedScreen(props) {
  let { balances } = props;

  return (<div>
    <h1 className="connected-screen__title">Your balances:</h1>
    { balances.length === 0 ? <p>{NO_TOKENS_MSG}</p> : <p className="connected-screen__description">{TOKENS_MSG}</p>}
    <div className="connected-screen__assets">
      {balances.map((token, i) => (
        <Asset key={i} index={i} token={token} />
      ))}
    </div>
  </div>);
}

const mapStateToProps = ({
  balances,
 }) => ({
  balances,
});

export default connect(
  mapStateToProps
)(ConnectedScreen);
