import React from 'react';
import { connect } from 'react-redux';

import './Header.css';

function Header({
  addressTruncated,
}) {
    return (<div className="header">
        <div className="header__logo" />
        {addressTruncated ? <div className="header__address">
            Your account: {addressTruncated}
        </div> : null}
    </div>)
}

const mapStateToProps = ({
  addressTruncated,
 }) => ({
  addressTruncated,
});

export default connect(
  mapStateToProps
)(Header);
