import React from 'react';

import './Footer.css';

export default function Footer() {
    return (<div className="footer">
        <p>Our <a href="https://tgeapp.com/terms-and-conditions/">Terms of Use</a> and <a href="https://tgeapp.com/privacy-policy/">Privacy Policy</a>.</p>
        <p>
            <a href="mailto:support@centrality.ai?subject=TGE Withdrawal App - Support Request">
                <i className="footer__sign-out-icon" />
                Having trouble?
            </a>
        </p>
    </div>)
}