import BN from 'bignumber.js';

BN.config({ DECIMAL_PLACES: 18 });

export function Balance(
    name,
    address,
    decimals,
    rawBalance,
) {
    const balance = new BN(rawBalance).dividedBy(Math.pow(10, 18));
    // add commas to the display balance
    // ---------------------------------
    // do some hacks, as safari does not support
    // negative lookbehinds, meaning we cannot do this
    // with a single regex
    // hack involves splitting the string at the decimal place

    const stringBalance = balance.toFixed();

    // split real component of balance from decimal component
    let [real, decimal] = stringBalance.split('.');
    // add commas to real component of number
    real = real.replace(/(\d)(?=(\d\d\d)+$)/g, "$1,");
    // reattach decimal component to real component if it exists
    let displayBalance = real;
    let displayFullBalance = real;

    // replace decimal component
    if (decimal) {
        displayBalance += `.${decimal.substr(0, 6)}`; // display value is truncated to 6 decimal places
        displayFullBalance += `.${decimal}`; // just in case we need all decimal places
    }

    // if display balance has been truncated, add ellipses
    if (displayBalance !== displayFullBalance) {
        displayBalance += '...';
    }

    const withdrawing = false;

    return {
        name,
        address,
        decimals,
        rawBalance,
        displayBalance,
        displayFullBalance,
        balance,
        withdrawing,
    };
};