import { getProviderSelectedAddress } from "../core";

import { getContractInterfaces } from "../contracts";

import { tokens } from "../../fixtures/tokens";

import { Balance } from '../../store/structs';

const getTokenBalances = async (tokens, address) => {
  const contracts = await getContractInterfaces();

  return Promise.all(tokens.map(async (token, i) => {
    return contracts.TGE
      .methods
      .tokens(token.address, address)
      .call({ from: address })
      .then((balance) => (Balance(
        token.name,
        token.address,
        token.decimals,
        balance,
      )))
  }));
};

export const getERC20Tokens = async () => {
  const address = await getProviderSelectedAddress();
  const balances = await getTokenBalances(tokens, address);

  return {
    address,
    balances,
  };
};

export const withdrawToken = async (sender, contract, amount) => {
  const contracts = await getContractInterfaces();

  return contracts.TGE
    .methods
    .withdrawToken(contract, amount)
    .send({ from: sender });
};

export const withdrawETH = async (sender, amount) => {
  const contracts = await getContractInterfaces();

  return contracts.TGE
    .methods
    .withdraw(amount)
    .send({ from: sender });
};