const Token = (name, address, decimals) => ({
  name, address, decimals,
});

export const tokens = [
  Token('ETH',	'0x0000000000000000000000000000000000000000', 18),
  Token('ARDAT',	'0x0a63cdce75c04aa76d2b5efff6550940115ce20c', 18),
  Token('ARDAT-A',	'0xC1119Db61253dB67208A84f97836236C8467b705', 18),
  Token('CENNZ',	'0x1122b6a0e00dce0563082b6e2953f3a943855c1f', 18),
  Token('CENNZ-A',	'0x8d85f8d69451d41dc266c920971b4aac1120257a', 18),
  Token('CERTI',	'0x64d36862a2144599606670c682ebc64170be1a82', 18),
  Token('CERTI-A',	'0xd4fffa07929b1901fdb30c1c67f80e1185d4210f', 18),
  Token('PLUG',	'0x47da5456bc2e1ce391b645ce80f2e97192e4976a', 18),
  Token('PLUG-A',	'0xe254e0878605a3d8b24d6cf7b4737a8431df1da1', 18),
  Token('PLUG-B',	'0xe66894c93cbbe66e0fb2fcf5e430229bf5bb5ed9', 18),
  Token('PLUG-C',	'0x1ef74aa486558b3a7f8d1beb6613d6247765e7fe', 18),
  Token('SNGJ',	'0x249f71f8d9da86c60f485e021b509a206667a079', 18),
  Token('SSK',	'0x37da41f0962848011276b61e7982806dc1e0ba82', 18),
  Token('SWAPY',	'0xa426e7662d4726ab833b2b99f017503ed41114e8', 18),
  Token('SYLO',	'0xf293d23bf2cdc05411ca0eddd588eb1977e8dcd4', 18),
  Token('SYLO-A',	'0x3ec99d9ab6e6f144d47bce3c0f6183b23f9ff0ec', 18),
  Token('UVP',	'0xbf05ad27ad0412601e9495fab63c16422869be94', 18),
  Token('XES',	'0xa017ac5fac5941f95010b12570b812c974469c2c', 18),
  Token('XES-A',	'0x8ca33160a8a2a9cb6d435f843bdd176241e2237e', 18),
];
