import React from 'react';

import { withdrawUserToken } from '../utils';

import './Asset.css';

const LOADING_MSG = "Processing...";
const WITHDRAW_MSG = "Withdraw";

export default function Asset(props) {
    return (
        <div className="asset">
            <div className="asset__name">{props.token.name}</div>
            <div
                title={props.token.displayFullBalance}
                className="asset__balance"
            >
                {props.token.displayBalance}
            </div>
            <button
                onClick={() => withdrawUserToken(props.index)}
                disabled={props.token.withdrawing}
            >
                {props.token.withdrawing ? LOADING_MSG : WITHDRAW_MSG}
            </button>
        </div>
    );
}
